
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import * as smartsupp from 'smartsupp-widget';

import DataTable from '@/components/datatables/KTDataTable.vue';
import HeaderImage from '@/components/dashboard/HeaderImage.vue';
import { Actions, Getters } from '@/store/enums/ReferralEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

import { capitalize } from '@/utils/text';

import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'referral-list',
  components: {
    HeaderImage,
    DataTable,
  },
  data: () => ({
    dataTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
      loading: false,
    },
    loading: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      referrals: Getters.GET_REFERRALS,
    }),
    dataTableHeader() {
      return [
        {
          name: 'Name',
          key: 'user',
          align: 'center',
        },
        {
          name: 'State',
          key: 'state',
        },
        {
          name: 'Sign Up Date',
          key: 'sign_up_date',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchReferrals: Actions.FETCH_REFERRALS,
    }),
    formatList(data) {
      return data.map((item) => {
        return {
          id: item.id,
          first_name: `${item.first_name}`,
          last_name: `${item.last_name}`,
          email: item.email,
          state: item.state,
          sign_up_date: item.sign_up_date,
        };
      });
    },

    async init() {
      this.loading = true;

      await this.fetchReferrals()
        .then(() => {
          this;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleToggleChat() {
      this.handleSmartSuppName();

      smartsupp.chatOpen();
      return false;
    },

    handleSmartSuppName() {
      const firstName = this.authUser?.first_name
        ? this.authUser?.first_name
        : '';
      const lastName = this.authUser.last_name ? this.authUser.last_name : '';
      const email = this.authUser.email ? this.authUser.email : '';
      const mobile = this.authUser.mobile ? this.authUser.mobile : '';

      const name = firstName && lastName ? `${firstName} ${lastName}` : '';

      smartsupp.setName(name);
      smartsupp.setEmail(email);
      smartsupp.setPhone(mobile);
    },

    formatName(name: string) {
      return capitalize(name);
    },

    handleCopyToClipboard(user) {
      navigator.clipboard
        .writeText(user.referral_link)
        .then(() => {
          ElMessage({
            message: 'Copied to clipboard!',
            type: 'success',
          });
        })
        .catch(() => {
          ElMessage.error('Oops, unable to copy to clipboard');
        });
    },
  },

  watch: {
    referrals: {
      async handler(values) {
        const rowData = await values;

        this.dataTable.items = this.formatList(rowData);
        this.dataTable.total = rowData.length;
      },
      immediate: true,
    },
  },
});
